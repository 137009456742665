import React from 'react'
import "./teacherprofile.css"
import AdminPortalNav from '../../../components/AdminPortalNav'
import { useParams,useNavigate } from 'react-router-dom'
import {useState,useEffect} from "react"
import axios from "axios"
import Swal from "sweetalert2"

const TeacherProfile = () => {
  const [activeBtn,setActiveBtn]=useState()
    const id=useParams()
    const navigate=useNavigate()
    const [teacherData,setTeacherData]=useState()
    const [update,setUpdate]=useState()
    const hostname = process.env.REACT_APP_SERVER
    async function studentProfileDetails(){
      try{
          const teacherdetailsdata=await axios.get(`${hostname}/api/v1/admin/teacher-details/${id.id}`)
          if(teacherdetailsdata){
             setTeacherData(teacherdetailsdata)
          }

      }catch(err){
        console.log(err)
          
      }
  }

  useEffect(()=>{
    studentProfileDetails()
    console.log(teacherData)
  },[activeBtn])
  async function EnableDisableHandler(){
      try{
        if(teacherData.data[0].isActive==true){
          const data=await axios.patch(`${hostname}/api/v1/admin/status/${id.id}`,{
            isActive:false
          })
          if(data){
          
            Swal.fire("Change the Status SuccessFully")
            navigate("/admin/portal")
          }
        }else if(teacherData.data[0].isActive==false){
          const data=await axios.patch(`${hostname}/api/v1/admin/status/${id.id}`,{
            isActive:true
          })
          if(data){
            // setActiveBtn(false)
            Swal.fire("Change the Status SuccessFully")
            navigate("/admin/portal")
          }
        }
      }catch(err){
        console.log(err)
      }
  }
  return (
    <div>
       <AdminPortalNav required={true} HeaderName="ADMIN" routeName="admin"/>
       <div className='adminstudentprofilecontainer'>
            <div className='adminstudentprofileminicontainer1'>
                 <h4>Teacher Details</h4>
                 <span style={{cursor:"pointer"}} onClick={EnableDisableHandler}>{teacherData?!teacherData.data[0].isActive?"Enable":"Disable":''}</span>
            </div>      
            <div className='adminstudentprofileminicontainer2'>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='firstname'>FirstName</label>
                          <input placeholder='FirstName' value={teacherData?teacherData.data[0].firstName:''} id="firstname" />
                        </div>
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='lastName'>LastName</label>
                          <input placeholder='LastName' value={teacherData?teacherData.data[0].lastName:''} id="lastName" />
                        </div>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='BirthDate'>BirthDate</label>
                          <input placeholder='FirstName' value={teacherData?teacherData.data[0].birthDate:''} id="BirthDate" />
                        </div>
                        <div className='d-flex flex-column text-start m-2 gendershortinput'>
                          <label htmlFor='Gender'>Gender</label>
                          <input placeholder='Gender' value={teacherData?teacherData.data[0].gender:''} id="Gender" />
                        </div>  
            </div>
            <div>
                      <div className='d-flex flex-column text-start m-1 addressinputstudentportal'>
                          <label htmlFor='Address'>Address</label>
                          <input placeholder='Address' value={teacherData?teacherData.data[0].address:''} id="Address" />
                        </div> 
            </div>
            <div>
  
            </div><div className='adminstudentprofileminicontainer2'>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='city'>IsActive</label>
                          <input placeholder='city' disabled value={activeBtn?activeBtn:teacherData?teacherData.data[0].isActive:''}  id="city" />
                        </div>
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='state'>State</label>
                          <input placeholder='state' value={teacherData?teacherData.data[0].state:''} id="state" />
                        </div>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='postal/zip'>Postal/Zip</label>
                          <input placeholder='Postal' value={teacherData?teacherData.data[0].zipcode :''} id="postal/zip" />
                        </div>
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='mobile'>Mobile Number</label>
                          <input placeholder='Mobile' value={teacherData?teacherData.data[0].mobileNumber:''} id="mobile" />
                        </div>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='email'>Email</label>
                          <input placeholder='Email' value={teacherData?teacherData.data[0].email:''} id="email" />
                        </div>
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='education'>Education</label>
                          <input placeholder='Education' value={teacherData?teacherData.data[0].education:''} id="education" />
                        </div>                   
            </div>
       </div>
    </div>
  )
}

export default TeacherProfile