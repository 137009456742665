import React from 'react'
import "./homeandregistration.css"
import { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../context/User/user.context'
import {AdminContext} from "../context/Admin/Admin.context"
import Swal from "sweetalert2"
const hostname = process.env.REACT_APP_SERVER

const Home = () => {
    const [admin,setAdmin]=useState(false)
    const [loginDetails,setLoginDetails]=useState({
        email:"",
        password:"",
        role:"student"
    })
    const navigate=useNavigate()
    async function adminClickHandler(){
        try{
            if(admin===false){
                setAdmin(true)
            }else{
                setAdmin(false)
            }
        }catch(err){
            console.log(err)
        }
    }

    async function StudentRegistrationHandler(){
          console.log("student k")
    }
    
    const {setStudentLoginData}=useContext(UserContext)
    async function LoginClickHandler(){
         try{
              const logindata=await axios.post(`${hostname}/api/v1/auth/login`,{
                email:loginDetails.email,
                password:loginDetails.password,
                role:loginDetails.role
              })
                if(logindata){
                    setStudentLoginData(logindata)
                    localStorage.setItem("loginData",JSON.stringify(logindata))
                     Swal.fire("loggedin successfully")
                     navigate("/studentportal")
                }
         }catch(err){
            console.log(err)
            Swal.fire("Invalid Email/password,Please Try Again")
         }
    }
    
    const {setAdminLoginDetailsContext,setTeachersdataadmin,setStudentdataforadmin}=useContext(AdminContext)
    async function LoginAdminHandler(){
        try{
            const data=await axios.post(`${hostname}/api/v1/admin/login`,{
                userName:loginDetails.email,
                password:loginDetails.password
            },{
                headers:{
                    'Allow-Control-Allow-Orgin':'*',
                    "Content-Type": "application/json",
                }
            })
            if(data){
                setAdminLoginDetailsContext(data)
                localStorage.setItem('admintoken',"x")
                Swal.fire("admin logged in")
                const datastudent=await axios.get(`${hostname}/api/v1/admin/students`)
                const datateacher=await axios.get(`${hostname}/api/v1/admin/teachers`)
                if(datastudent && datateacher){
                    setStudentdataforadmin(datastudent.data.result)
                    setTeachersdataadmin(datateacher.data.result)
                    navigate("/admin/portal")
                }else{
                    console.log("something went wrong!")
                }
            }
        }catch(err){
            console.log(err)
            Swal.fire("Invalid UserName/Email, Please Try Again")
        }
    }
  return (
    <div className='HomeContainer'>
            <Link to="/teacher"><button className='homecontainerbtn2'>Teacher</button></Link>
            <button className='homecontainerbtn' onClick={adminClickHandler}>{!admin?"ADMIN":"Student"}</button>
                <div className='logincontainer'>
                    <div className='logincontentcontainer'>
                        <h3 style={{fontSize:"x-large"}} >Login</h3>
                            <div className='inputcontentcontainer'>
                                <label htmlFor='idusername'>Email</label>
                                <input type="text" name="email" onChange={(e)=>setLoginDetails({...loginDetails,email:e.target.value})} id="idusername" />
                                <label htmlFor='idpassword'>Password</label>
                                <input type="password" name="password" onChange={(e)=>setLoginDetails({...loginDetails,password:e.target.value})} id="idpassword" />
                            </div>
                    <div className="mt-1">
                    <button className='homecontainerlsbtn' onClick={admin?LoginAdminHandler:LoginClickHandler} >Login</button>
                    {!admin?<Link to="/registration"><button className='homecontainerlsbtn' style={{margin:'1rem'}} 
                    onClick={StudentRegistrationHandler} 
                    >Register</button></Link>:''}
                </div>   
                    {!admin?<Link className='forgotpassword' to="/student/forgotpassword/page1">Forgot Password<i className='fa-solid fa-arrow-right mx-1'></i></Link>:''}
             </div>
            </div>
         </div>
 
  )
}

export default Home
