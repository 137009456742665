import React,{ useState } from 'react'
// import "./homeandregistration.css"
import { Link } from 'react-router-dom'
import axios from "axios"
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2"

const TeacherRegistration = () => {
      const hostname = process.env.REACT_APP_SERVER
      const navigate=useNavigate()
      const [teacherDetails,setTeacherDetails]=useState({
            firstName: "",
            lastName: "",
            birthDate: "",
            gender: "",
            address: "",
            zipCode:"",
            email: "",
            userName:"",
            education:"",
            state:"",
            mobileNumber:"",
            password: "",
            role: "teacher",
            otp:""
      })
      const [confirmPassword,setConfirmPassword]=useState()
      const [eligibilityForRegistration,setEligibilityForRegistration]=useState(false)

      async function SubmitTeacherRegistrationOtpHandler(){
            try{
                if(teacherDetails.mobileNumber.length==10){
                  if(teacherDetails.password==confirmPassword){
                        if(teacherDetails.email!=="" && teacherDetails.email.includes('@')){
                              const data=await axios.post(`${hostname}/api/v1/auth/register`,teacherDetails)
                              console.log(data) 
                              if(data){
                              Swal.fire("Otp Send")
                              setEligibilityForRegistration(true)
                              }
                        }else{
                              Swal.fire("Provide a valid Email")
                        }
                   }else{
                         Swal.fire("password do not match")
                   }

                }else{
                  Swal.fire("Mobile Number length must be 10")
                }
            }catch(err){
                  console.log(err)
            }
      }
      
      async function SubmitTeacherRegistrationHandler(){
            try{
                  const data=await axios.post(`${hostname}/api/v1/otp/verify-otp-register`,{data:teacherDetails,otp:teacherDetails.otp})
                  console.log(data) 
                  if(data){
                   Swal.fire("successfully Registered")
                   setEligibilityForRegistration(false)
                        navigate("/")
                  }
            }catch(err){
                  console.log(err)
            }
      }
  return (
    <div className='HomeContainer'>
                <div className='registrationcontainer'>
                    <div className='logincontentcontainer'>
                        <h3 style={{fontSize:"x-large"}}><Link className='text-decoration-none' to="/teacher"><i className="fa-solid fa-circle-arrow-left" style={{color:"rgb(3,56,104)"}}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Register</h3>
                            <div className='inputcontentcontainerRegistration'>
                                 <div className='inputcontentcontainerRegistrationleftside'>
                                        <label htmlFor='firstName'>FirstName</label>
                                        <input type="text" name="firstName" onChange={(e)=>setTeacherDetails({...teacherDetails,firstName:e.target.value})} id="firstName" />
                                        <label htmlFor='birthdate'>Birth Date</label>
                                        <input type="date" name="birthDate" onChange={(e)=>setTeacherDetails({...teacherDetails,birthDate:e.target.value})} id="birthdate" />
                                        <label htmlFor='postalcode'>Postal/Zip Code</label>
                                  <input type="text" name="postalcode" onChange={(e)=>setTeacherDetails({...teacherDetails,zipCode:e.target.value})} id="postalcode" />
                                 </div>
                                 <div className='inputcontentcontainerRegistrationrightside'>
                                        <label htmlFor='lastname'>LastName</label>
                                        <input type="text" name="lastName" onChange={(e)=>setTeacherDetails({...teacherDetails,lastName:e.target.value})} id="lastname" />
                                        <label htmlFor='gender'>Gender</label>
                                        <select className='genderselectbox' onChange={(e)=>setTeacherDetails({...teacherDetails,gender:e.target.value})} id="gender">
                                          <option  value="" selected disabled hidden>Choose here</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <label htmlFor='state'>State</label>
                                  <input type="text" name="state" onChange={(e)=>setTeacherDetails({...teacherDetails,state:e.target.value})} id="state" />
                                 </div>
                            </div>
                            <div className='d-flex flex-column text-start addressinput'>
                                        <label htmlFor='Address'>Address</label>
                                        <input type="text" id="Address" name="address" onChange={(e)=>setTeacherDetails({...teacherDetails,address:e.target.value})} className='w-full'/>
                            </div>    
                               
                            <div className='restitemscontent'>
                                  <div className='inputcontentcontainerRegistrationleftside'>
                                        <label htmlFor='email'>Email</label>
                                        <input type="email" name="email" onChange={(e)=>setTeacherDetails({...teacherDetails,email:e.target.value})} id="email" required />
                                        <label htmlFor='password'>Password</label>
                                        <input type="password" name="password" onChange={(e)=>setTeacherDetails({...teacherDetails,password:e.target.value})} id="password" />
                                        <label htmlFor='number'>Mobile Number</label>
                                        <input type="text" name="mobileNumber" onChange={(e)=>setTeacherDetails({...teacherDetails,mobileNumber:e.target.value})} id="number" />
                                        {eligibilityForRegistration?
                                        <>
                                        <label htmlFor='otp'>OTP</label>
                                        <input type="text" name="otp" onChange={(e)=>setTeacherDetails({...teacherDetails,otp:e.target.value})} id="otp" />
                                        </>
                                        :''}
                                  </div>
                                  <div className='inputcontentcontainerRegistrationrightside'>
                                        <label htmlFor='username'>Username</label>
                                        <input type="text" name="userName" onChange={(e)=>setTeacherDetails({...teacherDetails,userName:e.target.value})} id="username" />
                                        <label htmlFor='cpassword'>Confirm Password</label>
                                        <input type="password" name="cpassword" onChange={(e)=>setConfirmPassword(e.target.value)} id="cpassword" />
                                        <label htmlFor='education'>Education</label>
                                        <input type="text" name="education" onChange={(e)=>setTeacherDetails({...teacherDetails,education:e.target.value})} id="education" />
                                        
                                    
                                        <button className='homecontainerlsbtn mt-4' onClick={eligibilityForRegistration?SubmitTeacherRegistrationHandler:SubmitTeacherRegistrationOtpHandler}>{eligibilityForRegistration?'Register':'Send Otp'}</button>
                                  </div>
                            </div>     
                    <div className="mt-1">
                   
                </div>   
             </div>
            </div>
         </div>
 
  )
}

export default TeacherRegistration



