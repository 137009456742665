import React, { useContext, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Navigation from '../../../components/Navbar'
import { UserContext } from '../../../context/User/user.context'
import "./finalscreen.css"


const FinalScreen = () => {
    const {examname,examid,answersArray,totalAttempts,Time,minutes}=useParams()
    const examContent=JSON.parse(localStorage.getItem("examContent"))
    useEffect(()=>{
        console.log(examname,examid)
        console.log(answersArray)
        console.log(totalAttempts)
        console.log(typeof(totalAttempts))
        console.log(typeof(answersArray))
        console.log(examContent)
    },[])
    const studentLoginDetails=JSON.parse(localStorage.getItem('studentLoginDetails'))
    var questions=examContent.data.questions
  return (
    <div>
      <Navigation required={true} 
      studentName={studentLoginDetails.data.student.firstName}
       />
       <div className='finalscreencontainer'>
        <h1>Summary</h1>
        <br />
        <div className='finalscreencardcontentcontainer'>
            <div className='smalllistcard'>
                   <h4>Section Name</h4>
                   <p>{examname[0].toUpperCase()+examname.slice(1)}</p>
            </div>
            <div className='smalllistcard'>
                   <h4>Score</h4>
                   <p>{answersArray!=0?answersArray.split(',').length:0}/{examContent.data.questions}</p>
            </div>
            <div className='smalllistcard'>
                   <h4>Attempted</h4>
                   <p>{totalAttempts!=0?totalAttempts.split(',').length:0}/{examContent.data.questions}</p>
            </div>
            <div className='smalllistcard'>
                   <h4>Accuracy</h4>
                   <p>{answersArray!=0?answersArray.split(',').length/questions*100+'%':0+'%'}</p>
            </div>
            <div className='smalllistcard'>
                   <h4>Time Remaining</h4>
                   <p>{minutes}:{Time} </p>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        {/* <Link to="/studentportal"><button className='finalscreenhomebtn'>Home</button></Link> */}
        <Link to="/"><button className='finalscreenhomebtn'>Exit</button></Link>
        <Link to={`/student/${examname}/${examid}/starttest/submit/review/${Time}/${minutes}`}><button className="finalscreenhomebtn" style={{marginLeft : "10px"}}>Review</button></Link>
       </div>
    
    </div>
  )
}

export default FinalScreen