import React, { useContext, useEffect } from 'react';
import Navigation from '../../../components/Navbar';
import "./examinstructionpage.css"
import { Link, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/User/user.context';
import axios from "axios"


const InstructionPage = () => {
    const { examname, examid } = useParams()
    const { setExamContent } = useContext(UserContext)
    const studentLoginDetails = JSON.parse(localStorage.getItem('studentLoginDetails'))
    const studentLoginData = JSON.parse(localStorage.getItem("loginData"))
    const hostname = process.env.REACT_APP_SERVER

    async function LodingExamContent() {
        const config = {
            headers: {
                Authorization: `Bearer ${studentLoginData ? studentLoginData.data.token : ''}`
            }
        }
        try {
            if (studentLoginData) {
                const examcontent = await axios.get(`${hostname}/api/v1/exam/${examid}`, config )
                // console.log(examcontent)
                localStorage.setItem("examContent", JSON.stringify(examcontent))
                setExamContent(examcontent)
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        LodingExamContent()
    }, [])

    return (
        <div className='instructionpagecontainer'>
            <Navigation required={true} studentName={studentLoginDetails.data.student.firstName} />
            <div className='instructionpagecontentcontainer'>
                <div className="instructionpageheading">
                    <h2>GENERAL INSTRUCTIONS</h2>
                </div>
                <div className='m-4 text-start '>
                    <h5 style={{ fontWeight: '400', marginTop: '1.5rem' }}>
                        <span style={{ fontWeight: 'bold' }}>1.</span>&nbsp;&nbsp;The examination does not require using any paper, pen, pencil and calculator.
                    </h5>
                    <h5 style={{ fontWeight: '400', marginTop: '1.5rem' }}>
                        <span style={{ fontWeight: 'bold' }}>2.</span>&nbsp;&nbsp;Every student will take the examination on a Laptop/Desktop/Smart Phone.
                    </h5>
                    <h5 style={{ fontWeight: '400', marginTop: '1.5rem' }}>
                        <span style={{ fontWeight: 'bold' }}>3.</span>&nbsp;&nbsp;On computer screen every student will be given objective type Multiple Choice Questions (MCQs).
                    </h5>
                    <h5 style={{ fontWeight: '400', marginTop: '1.5rem' }}>
                        <span style={{ fontWeight: 'bold' }}>4.</span>&nbsp;&nbsp;The students just need to click on the Right Choice / Correct option from the multiple choices /options given with each question. For Multiple Choice Questions, each question has four options, and the candidate has to click the appropriate option.
                    </h5>
                    <h5 style={{ fontWeight: '400', marginTop: '1.5rem' }}>
                        <span style={{ fontWeight: 'bold' }}>5.</span>&nbsp;&nbsp;The system automatically shuts down when the time limit is over OR alternatively if examinee finishes the exam before time he can quit by pressing the ‘End Test’ button. The students don’t click the “END TEST” Button until the student want to quit from Examination
                    </h5>

                    <br />
                </div>
                <Link to={`/student/${examname}/${examid}/starttest/pretestscreen`}>
                    <button className="instructionpagenextbtn">Next</button>
                </Link>
            </div>
        </div>
    )
}

export default InstructionPage