import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AdminPortalNav from '../../../components/AdminPortalNav'
import "./index.css"
import axios from "axios"

const StudentProfileAdmin = () => {
  const [preparingfor,setPreparingFor]=useState(['afcat','cds','nds'])
  const [studentData,setStudentData]=useState()
  const id=useParams()
  const hostname = process.env.REACT_APP_SERVER
  async function studentProfileDetails(){
      try{
          const studentdetailsdata=await axios.get(`${hostname}/api/v1/admin/student-details/${id.id}`)
          if(studentdetailsdata){
             console.log(studentdetailsdata)
             setStudentData(studentdetailsdata)
             var arr=[]
             for(let i=0;i<studentdetailsdata.data[0].preparingFor.length;i++){
                arr.push(studentdetailsdata.data[0].preparingFor[i].label)
             }
             setPreparingFor(arr)
          }
      }catch(err){
        console.log(err)
      }
  }
  useEffect(()=>{
    studentProfileDetails()
  },[])
  
  return (
    <div>
       <AdminPortalNav required={true} HeaderName="ADMIN" routeName="admin"/>
       <div className='adminstudentprofilecontainer'>
            <div className='adminstudentprofileminicontainer1'>
                 <h4>Student Details</h4>
                 {/* <span>Disable</span> */}
            </div>      
            <div className='adminstudentprofileminicontainer2'>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='firstname'>FirstName</label>
                          <input placeholder='FirstName' value={studentData?studentData.data[0].firstName:''}  id="firstname" />
                        </div>
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='lastName'>LastName</label>
                          <input placeholder='LastName' value={studentData?studentData.data[0].lastName:''} id="lastName" />
                        </div>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='BirthDate'>BirthDate</label>
                          <input placeholder='FirstName' value={studentData?studentData.data[0].birthDate:''} id="BirthDate" />
                        </div>
                        <div className='d-flex flex-column text-start m-2 gendershortinput'>
                          <label htmlFor='Gender'>Gender</label>
                          <input placeholder='Gender' value={studentData?studentData.data[0].gender:''} id="Gender" />
                        </div>  
            </div>
            <div>
                      <div className='d-flex flex-column text-start m-1 addressinputstudentportal'>
                          <label htmlFor='Address'>Address</label>
                          <input placeholder='Address' value={studentData?studentData.data[0].address:''} id="Address" />
                        </div> 
            </div>
            <div>
  
            </div><div className='adminstudentprofileminicontainer2'>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='city'>City</label>
                          <input placeholder='city' value={studentData?studentData.data[0].city:''} id="city" />
                        </div>
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='state'>IsActive</label>
                          <input placeholder='state' disabled value={studentData?studentData.data[0].isActive:''} id="state" />
                        </div>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='postal/zip'>Postal/Zip</label>
                          <input placeholder='Postal' value={studentData?studentData.data[0].zipcode:''} id="postal/zip" />
                        </div>
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='mobile'>Mobile Number</label>
                          <input placeholder='Mobile' value={studentData?studentData.data[0].mobileNumber:''} id="mobile" />
                        </div>
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='email'>Email</label>
                          <input placeholder='Email' value={studentData?studentData.data[0].email:''} id="email" />
                        </div>
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='school'>School Name</label>
                          <input placeholder='School' value={studentData?studentData.data[0].schoolName:''} id="school" />
                        </div>  
                        <div className='d-flex flex-column text-start m-2 marginrightinput'>
                          <label htmlFor='education'>Education</label>
                          <input placeholder='Education' value={studentData?studentData.data[0].education:''} id="education" />
                        </div>  
                        <div className='d-flex flex-column text-start m-2'>
                          <label htmlFor='preparingfor'>Preparing For</label>
                          <input placeholder='PreparingFor'  value={preparingfor} className="inputcontentstyle" id="preparingfor" />
                        </div>    
            </div>
       </div>
    </div>
  )
}

export default StudentProfileAdmin