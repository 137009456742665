import React from 'react'
import "./navbar.css"
import { Link } from "react-router-dom"
import {useContext,
        useEffect} from "react"
import {UserContext} from "react"
import Spinner from 'react-bootstrap/Spinner';

function BasicExample() {
  return (
    <Spinner animation="border" size="sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}
async function LogoutClickHandler(){
  try{
      localStorage.removeItem("loginData")
      localStorage.removeItem("studentLoginDetails")
      localStorage.removeItem("examContent")
      window.location.href="/"
  }catch(err){
    console.log(err)
  }
}
const Navigation = (props) => {

  return (
    <div className="NavbarCustom">
        {props.required?<h3><Link to="/studentportal" className='gobackcolor'>
          <i className="fa-solid fa-circle-arrow-left"></i>
        </Link>&nbsp;Student Dashboard</h3>:<h3>Student Dashboard</h3>}
        <ul>
           <Link to="/student/wallet" className='text-decoration-none'><li>Wallet</li></Link>
           <li onClick={LogoutClickHandler}>Logout</li>
           <Link to="/student/portal/profile" className='text-decoration-none'><li>{props.studentName?props.studentName:<BasicExample />}</li></Link>
        </ul>
    </div>
  )
}

export default Navigation