import React,{useContext, useEffect, useState} from 'react'
import Navigation from '../../../components/Navbar'
import "./pretestscreen.css"
import { useParams,Link } from 'react-router-dom'
import { UserContext } from '../../../context/User/user.context'
import axios from "axios"

const PreTestScreen = () => {
  const [checked,setChecked]=useState(false)
  const hostname = process.env.REACT_APP_SERVER
    let examname=useParams()
      examname.examname=examname.examname[0].toUpperCase()+examname.examname.slice(1)
      let {examid}=useParams()
      console.log(examname,examid)
      function changeHandler(e){
            console.log(e.target.checked)
             setChecked(e.target.checked)
      } 
      const examContent=JSON.parse(localStorage.getItem("examContent"))
      const studentLoginData=JSON.parse(localStorage.getItem("loginData"))
      const studentLoginDetails=JSON.parse(localStorage.getItem("studentLoginDetails"))
      useEffect(()=>{
         window.onpopstate=(event)=>{
          // history.go(1)
         }
      },[])

      const config={
        headers:{
              Authorization:`Bearer ${studentLoginData.data.token}`,
              "Access-Control-Allow-Origin": "*",
            }
          }

      async function updateExamStatus(){
            const studentRemoveExam=await axios.patch(`${hostname}/api/v1/student/updatetakenexamstatus`,{
              examid,
              id:studentLoginDetails.data.student._id
            },config)
             if(studentRemoveExam){
              console.log("removed")
             }
      }
  return (
    <div>
       <Navigation required={true} studentName={studentLoginDetails.data.student.firstName}/>
       <div className='pretestscreencontainer'>
            <div className='pretestscreenheading'>
                <h2>{examname.examname}</h2>
            </div>
            <div className='pretestscreenduration'>
                 <h4>Duration:&nbsp;{examContent?examContent.data.time:''}{" "}Min</h4>
                 <h4>Maximum Marks:&nbsp;{examContent?examContent.data.credit:''}</h4>
            </div>
            <ol>
                <h4>Read The Following Instructions Carefully:</h4>
               <li>The total number of questions are 100, duration of examination, will be 60min.</li>
               <li>All questions are compulsory and each carries One mark.</li>
               <li>Try not to guess the answer as there is negative marking </li>
               <li>There will be 0.5 NEGATIVE MARKING for the wrong answers.</li>
            </ol>
            <div className="text-start m-4">
              <h4>
                Declaration
              </h4>
              <input type="checkbox" onChange={changeHandler} />I have read all the instructions and ready to take the exam
            </div>
            <Link to={`/student/${examname.examname.toLowerCase()}/${examid}/starttest/test`}><button className={checked?'preteststartbtn':'preteststartbtndisabled'} disabled={checked?false:true} onClick={updateExamStatus}>
                  I Am Ready To Begin
            </button></Link>
       </div>
    </div>
  )
}

export default PreTestScreen