import React from 'react'
import "./homeandregistration.css"
import { Link } from 'react-router-dom'
import { useState } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { TeacherContext } from '../context/Teacher/teacher.context'
import { useContext } from 'react'
import Swal from 'sweetalert2'

const Teacher = () => {
    const hostname = process.env.REACT_APP_SERVER
    const [teacherLoginData,setTeacherLoginData]=useState({
          email:"",
          password:"",
          role:"teacher"
    })

    const navigate=useNavigate()
    const {setTeacherToken}=useContext(TeacherContext)

    async function StudentRegistrationHandler(){
          console.log("student k")
    }

    async function TeacherLoginHandler(){
        try{
            const logindata=await axios.post(`${hostname}/api/v1/auth/login`,teacherLoginData)
              if(logindata.status===200){
                  setTeacherToken(logindata)
                  const savedata=localStorage.setItem("teacherToken",JSON.stringify(logindata))
                  console.log(savedata)
  
                      Swal.fire("loggedin successfully")
                      navigate("/teacher/portal")
        
              }
       }catch(err){
          console.log(err)
          Swal.fire("Input Email/Password is wrong.Please Try Again")
       }
    }
  return (
    <div className='HomeContainer'>
            <Link to="/"><button className='homecontainerbtn2'>Student</button></Link>
                <div className='logincontainer'>
                    <div className='logincontentcontainer'>
                        <h3 style={{fontSize:"x-large"}} >Login</h3>
                            <div className='inputcontentcontainer'>
                                <label htmlFor='idusername'>Email</label>
                                <input type="email" name="email" onChange={(e)=>setTeacherLoginData({...teacherLoginData,email:e.target.value})} id="idusername" />
                                <label htmlFor='idpassword'>Password</label>
                                <input type="password" onChange={(e)=>setTeacherLoginData({...teacherLoginData,password:e.target.value})} id="idpassword" />
                            </div>
                    <div className="mt-1">
                    <button className='homecontainerlsbtn' onClick={TeacherLoginHandler}>Login</button>
                    <Link to="/teacher/registration"><button className='homecontainerlsbtn' style={{margin:'1rem'}} 
                    onClick={StudentRegistrationHandler} 
                    >Register</button></Link>
                </div>   
                    <Link className='forgotpassword' to="/teacher/forgotpassword/page1">Forgot Password<i className='fa-solid fa-arrow-right mx-1'></i></Link>
             </div>
            </div>
         </div>
 
  )
}

export default Teacher
