import React from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"
import {useState} from "react"
import {useParams} from "react-router-dom"
import {useNavigate} from "react-router-dom"
import {useContext} from "react"
import { OTPContext } from '../../context/otp/otp.context'
import Swal from "sweetalert2"

const Page1 = () => {
  const [email,setEmail]=useState()
  const {role}=useParams()
  const navigate=useNavigate()
  const {setForgotOtpPage1}=useContext(OTPContext)
  const hostname = process.env.REACT_APP_SERVER


  async function ForgotPassword(){
    try{
       const data=await axios.post(`${hostname}/api/v1/otp/forgot-password`,
            {email,role}
       )
       if(data){
        setForgotOtpPage1(data)
        Swal.fire("Otp Send To The Mail")
        navigate(`/${role}/forgotpassword/otpverification/page2`)
       }
    }catch(err){
      console.log(err)
      Swal.fire("Mail does not exist")
    }
  }
  return (
    <div className='HomeContainer'>
          <div className='logincontainer'>
                    <div className='logincontentcontainer'>
                        <h3 style={{fontSize:"x-large"}}><Link to="/"><i className="fa-solid fa-circle-arrow-left mt-2 iconcolorchange" color='rgb(3,56,104)'></i></Link>Forgot Password</h3>
                            <div className='inputcontentcontainer'>
                                <label htmlFor='email'>Email</label>
                                <input type="email" name="email" onChange={(e)=>setEmail(e.target.value)} id="email" />
                                
                            </div>
                    <div className="mt-1">
                      <button className='homecontainerlsbtn' onClick={ForgotPassword}>Send</button>
                </div>  
                    </div>
    </div>
    </div>
  )
}

export default Page1

{/* <Link to="/forgotpassword/otpverification/page2"> */}