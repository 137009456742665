import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import {useNavigate} from "react-router-dom"
import {OTPContext} from "../../context/otp/otp.context"
import { useContext } from "react"
import axios from "axios"
import Swal from "sweetalert2"

const Page3 = () => {
  const [newPassword,setNewPassword]=useState({
    password:'',
    cpassword:''
  })
  const navigate=useNavigate()
   const {forgotOtpPage1,setForgotOtpPage1}=useContext(OTPContext)
   const hostname = process.env.REACT_APP_SERVER

    async function NewPasswordHandler(){
      try{
           if(forgotOtpPage1){
               if(newPassword.password==newPassword.cpassword){
               const data=await axios.patch(`${hostname}/api/v1/otp/set-password`,{
                   data:forgotOtpPage1.data.data,
                   newPassword:newPassword.password
               })
               if(data){
                setForgotOtpPage1('')
                  navigate("/")
               }
              }else{
                Swal.fire("Password don't Match")
              }
           }else{
              Swal.fire("validation failed")
           }
      }catch(err){
        console.log(err)
      }
    }
  return (
    <div className='HomeContainer'>
          <div className='logincontainer'>
                    <div className='logincontentcontainer'>
                        <h3 style={{fontSize:"x-large"}}><Link to="/"><i className="fa-solid fa-circle-arrow-left mt-1 iconcolorchange" color='rgb(3,56,104)'></i></Link>&nbsp;Forgot Password</h3>
                            <div className='inputcontentcontainer'>
                                <label htmlFor='newpassword'>New Password</label>
                                <input id="newpassword" name="newpassword" 
                                 onChange={(e)=>setNewPassword({...newPassword,password:e.target.value})} type="password" placeholder="New password"/>
                                <label htmlFor='confirmpassword'>Confirm Password</label>
                                <input id="confirmpassword" type="password" 
                                  name="cpassword" onChange={(e)=>setNewPassword({...newPassword,cpassword:e.target.value})} placeholder="Confirm password"/>
                            </div>
                    <div className="mt-1">
                    <button className='homecontainerlsbtn' onClick={NewPasswordHandler}>Reset</button>
                </div>  
                    </div>
    </div>
    </div>
  )
}

export default Page3